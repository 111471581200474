.page {
  .page-container {
    max-width: 1024px;
    margin: auto;
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  .page-filtro {
    width: 100%;
  }
}

.page-header {
  width: 100%;
  clear: both;
  padding: 16px 0;
  padding-top: 26px;
  background: white;
  height: 80px;
  margin-bottom: 20px;
  display: flex;

  h3 {
    font-size: 22px;
    font-weight: 300;
    margin: 0;
  }

  .page-header-left {
    flex: 1;
  }

  .page-header-right {
  }
}
