.select-group-error {
    & > div > div {
      border-color: red;
   
    }
    & > div  {
      border-color: red !important;
  
      & > span {
  
      border-color: red !important;
      }
    }
}
