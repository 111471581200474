* {
    box-sizing: border-box;
}

body {
    font-family: 'Open Sans';
    background-color: #efefef;
    padding-top: 60px;
    font-size: 14px;
    overflow: visible !important;
}

.pagination {
    justify-content: flex-end;  
}
.page-item-right {  
    display: flex;
}

.page-item-left {   
    display: flex;
}

.breadcrumb {
    background: none;
    margin: 0;
    padding: 0;

    .breadcrumb-item {
        font-size: 14px;
        color: #999;
        margin-top: 3px;

        a {
            color: #333;
        }
    }
}


label {
    margin-bottom: 0;
    font-size: .9em;
}

.ant-table-placeholder {
    z-index: 0;
}

.pagination {
    margin-bottom: 0;
}