.navbar {

    &.fixed-top {
        z-index: 1;
    }

    .navbar-brand {
        margin-right: 40px;
        cursor: pointer;
    }

    .navbar-nav {

        .dropdown {
            &.nav-item {
                margin: 0 16px;
            }
        }
    }
 
    .navbar-logo {
        width: 40px;
        margin-right: 20px;
    } 
}


@media all and (max-width: 768px) {

    .navbar {
        .navbar-brand {
            margin-right: 0;
            font-size: 16px;
            
            img {
                margin-right: 6px;
            }
        }


        .navbar-nav {

            .dropdown {
                &.nav-item {
                    text-align: center
                }
            }
        }
    }
}